import React from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';


const Part6FirstPage = () => {

    const { t } = useTranslation(["partSixFirstPage", "actions"])

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const nextPage = () => {

        navigate('/part_6/att', { replace: true })

    };
    const previousPage = () => {

        navigate(`/part_6/the_problem_of_self-conciousness`, { replace: true })

    };

    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title> {t('partSixFirstPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-12 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">

                                        <div className="title mb-3">
                                        {t('partSixFirstPage:title')}
                                        </div>
                                        <p className='redText'>
                                        {t('partSixFirstPage:content.line1')}
                                        </p>
                                        <p>
                                        {t('partSixFirstPage:content.line2')}
                                        </p>
                                        <p>
                                        {t('partSixFirstPage:content.line3')}
                                        </p>
                                        <p>
                                        {t('partSixFirstPage:content.line4')}
                                        </p>
                                        <p>
                                        {t('partSixFirstPage:content.line5')}
                                        </p>
                                        <p>
                                        {t('partSixFirstPage:content.line6')}
                                        </p>
                                        <p>
                                        {t('partSixFirstPage:content.line7')}
                                        </p>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}
                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={nextPage}
                                        >
                                            {t('actions:continue')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>

    );
};

export default Part6FirstPage;