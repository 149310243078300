import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';
// import susan3Image from '../../../../public/images/part3/Susan3_edited.png'



const CasualBehaviours = () => {
    const { t } = useTranslation(["actions","partThreeCasualBehaviours"])

    // ============Local Storage ==========================

    const userInfo = localStorage.getItem('user'); 
    const user = JSON.parse(userInfo);
    const languageCode =user.userInfo.languageCode;


    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const nextPage = () => {

        navigate('/part_3/more_safety_behaviours', { replace: true })

    };

    const previousPage = () => {

        navigate(`/part_3/lets_recap`, { replace: true })

    };


    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partThreeCasualBehaviours:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-12 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partThreeCasualBehaviours:title')}
                                        </div>
                                        <div className='img-how-to-sty centre-align'>
                                            {/* <img src={`${DOMAIN_NAME}images/part3/susan3.png`} alt="susan" /> */}
                                            <img className='part2_susan3_image' src={`${DOMAIN_NAME}images/${languageCode}/part3/Susan3_edited.png`} alt={t("partThreeCasualBehaviours:alt")} />
                                            {/* <img className='part2_susan3_image' src={susan3Image} alt="susan" /> */}
                                            <p></p>
                                        </div>
                                        <p>
                                        {t('partThreeCasualBehaviours:content.line1')}
                                        </p>
                                        <p>
                                        {t('partThreeCasualBehaviours:content.line2')}
                                        </p>
                                        <p className='redText'>
                                            <u>{t('partThreeCasualBehaviours:content.line3')}</u>
                                        </p>
                                        <p>
                                        {t('partThreeCasualBehaviours:content.line4')}
                                        </p>
                                        <p className='redText'>
                                            <u>{t('partThreeCasualBehaviours:content.line5')}</u>
                                        </p>
                                        <p>
                                        {t('partThreeCasualBehaviours:content.line6')}
                                        </p>
                                        <p className='redText'>
                                            <u>{t('partThreeCasualBehaviours:content.line7')}</u>
                                        </p>
                                        <p>
                                        {t('partThreeCasualBehaviours:content.line8')}
                                        </p>
                                        <p className='redText'>
                                            <u>{t('partThreeCasualBehaviours:content.line9')}</u>
                                        </p>
                                        <p>
                                        {t('partThreeCasualBehaviours:content.line10')}
                                        </p>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}
                                        >
                                           {t("actions:previous")}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={nextPage}
                                        >
                                            {t("actions:continue")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CasualBehaviours;