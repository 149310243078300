import React from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';
// import robynImage from '../../../../public/images/part3/Robyn_edited.png'

const MoreSafetyBehaviours = () => {
    const { t } = useTranslation(['actions', 'partThreeMoreSafetyBehaviours']);

    // ============Local Storage ==========================

    const userInfo = localStorage.getItem('user'); 
    const user = JSON.parse(userInfo);
    const languageCode =user.userInfo.languageCode;


    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const nextPage = () => {

        navigate('/part_3/safety_behaviours', { replace: true })

    };

    const previousPage = () => {

        navigate(`/part_3/causal_thoughts_and_safety_behaviours`, { replace: true })

    };

    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partThreeMoreSafetyBehaviours:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-12 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">

                                        <div className="title mb-3">
                                        {t('partThreeMoreSafetyBehaviours:title')}
                                        </div>
                                        <p className='redText'>
                                        {t('partThreeMoreSafetyBehaviours:content.line1')}
                                        </p>
                                        <div className='table-res-sty'>
                                            <table border="0" className="table more-safety-behaviours">
                                                <tbody>
                                                    <tr className="headings">
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line2')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line3')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line4')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line5')}</td>
                                                        <td>
                                                            {/* <img alt="Robyn" src={`${DOMAIN_NAME}images/part3/robyn.png`} /> */}
                                                            <img alt="Robyn" src={`${DOMAIN_NAME}images/${languageCode}/
part3/Robyn_edited.png`} className='part3_more_safety_images' />
                                                            {/* <img alt="Robyn" src={robynImage} className='part3_more_safety_images'/> */}
                                                        </td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line6')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line7')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line8')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line9')}</td>
                                                        <td>
                                                            {/* <img alt="John" src={`${DOMAIN_NAME}images/part3/test1.1.jpg`} /> */}
                                                            <img alt="John" src={`${DOMAIN_NAME}images/${languageCode}/
part3/Test1.png`} className='part3_more_safety_images' />
                                                        </td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line10')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line11')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line12')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line13')}</td>
                                                        <td>
                                                            {/* <img alt="Zoe" src={`${DOMAIN_NAME}images/part3/christina.png`} /> */}
                                                            <img alt="Zoe" src={`${DOMAIN_NAME}images/${languageCode}/
part3/Christina_edited.png`} className='part3_more_safety_images' />
                                                        </td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line14')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line15')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line16')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line17')}</td>
                                                        <td>
                                                            {/* <img alt="Frank" src={`${DOMAIN_NAME}images/part3/d1.png`} /> */}
                                                            <img alt="Frank" src={`${DOMAIN_NAME}images/${languageCode}/
part3/d1_edited.png`} className='part3_more_safety_images' />
                                                        </td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line18')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line19')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line20')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line21')}</td>
                                                        <td>
                                                            {/* <img alt="Rachel" src={`${DOMAIN_NAME}images/part3/corina.png`} /> */}
                                                            <img alt="Rachel" src={`${DOMAIN_NAME}images/${languageCode}/
part3/corina_edited.png`} className='part3_more_safety_images' />
                                                        </td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line22')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line23')}</td>
                                                        <td>{t('partThreeMoreSafetyBehaviours:content.line24')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}
                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={nextPage}
                                        >
                                             {t("actions:continue")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MoreSafetyBehaviours;