import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useTranslation } from "react-i18next";
const LetsReview = () => {
  const { t } = useTranslation(["partOneLetsReview", "actions"]);
  /*******   Page Params  *******/

  const title = t("partOneLetsReview:title");

  const content = `<div><p>${t(
    "partOneLetsReview:description.line1"
  )}</p><p>${t("partOneLetsReview:description.line2")}</p><p>${t(
    "partOneLetsReview:description.line3"
  )}</p><div className='row border p-3'><div className='col-lg-4'><a href='/part_1/causal_thoughts' rel='prev' title=${t(
    "partOneLetsReview:description.linkTitle1"
  )}><b>‹</b> ${t(
    "partOneLetsReview:description.linkText1"
  )}</a> </div> <div className='col-lg-4'> <a href='/part_1/anxiety_is_important_in_stuttering' title=${t(
    "partOneLetsReview:description.linkTitle2"
  )}>${t(
    "partOneLetsReview:description.linkText2"
  )}</a> </div><div className='col-lg-4'><a href='/part_1/thinking_exercise_3' rel='next' title=${t(
    "partOneLetsReview:description.linkTitle3"
  )}>${t(
    "partOneLetsReview:description.linkText3"
  )} <b>›</b></a> </div> </div></div>`;

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate("/part_1/thinking_exercise_1_sample_answers", { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_1/thinking_exercise_1`, { replace: true });
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partOneLetsReview:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">{title}</div>
                    <div className="content">
                      <p>{t("partOneLetsReview:content.line1")}</p>
                      <p>{t("partOneLetsReview:content.line2")}</p>
                      <p>{t("partOneLetsReview:content.line3")}</p>
                    </div>
                  </div>
                  {/* <FooterOfPrint /> */}
                </div>
                <div className="row">
                  <div className="previous col-6">
                    <Button className="link-sty mb-2" onClick={previousPage}>
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    <Button className="link-sty mb-2" onClick={nextPage}>
                      {t("actions:continue")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default LetsReview;
