import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';

import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetExperimentCompleted } from '../../../../services/part4/experiment-completed';
import { BASE_URL } from '../../../../env/Baseurl';
import ErrorLogging from '../../../../services/error-logs';
import RefreshToken from '../../../../services/refresh-token';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';

const Part4EighthPage = () => {

    const { t } = useTranslation(["partFourEighthPage", "actions"])

    const [experimentCompleted, errorMessage] = GetExperimentCompleted();       // Get Experiment info from api

    /*******   States *******/

    const [disabled, setDisabled] = useState(true);
    const [state, setState] = useState({ data: [] });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorApiMessage, setErrorMessage] = useState('');
    const [textBoxValidation, setTextboxValidation] = useState({
        id: "",
        errorMessage: "",
        disabledMessage: ""
    });
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    let handleClose = () => setShow(false);

    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const specialCharactersRegex = /^[^a-zA-Z0-9]+$/;

    const handleChange = (inputId) => (e) => {
        const { value } = e.target;

        if (state.data.some(item => item.question_id === inputId)) {

            let updatedData = state.data.map(item => {
                if (item.question_id === inputId) {
                    return { ...item, "question_id": inputId, "user_responce": value };
                }
                return item;
            });

            setState({ data: updatedData });
        } else {
            setState(state => ({
                data: [...state.data, { "question_id": inputId, "user_responce": value }]
            }));
        }

        if (value !== "" || value === "") {
            specialCharactersRegex.test(
                value) ?
                setTextboxValidation({
                    id: inputId,
                    errorMessage: t('partFourEighthPage:textBoxValidation.validation1'),
                    disabledMessage: t('partFourEighthPage:textBoxValidation.validation2')
                })
                :
                setTextboxValidation({
                    id: inputId,
                    errorMessage: "",
                    disabledMessage: t('partFourEighthPage:textBoxValidation.validation3')
                })
        };

    };

    const postSubmission = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "data": state.data,
                "saveasdraft": "false"
            });
            const response = await axios.post(BASE_URL + `experiment_completed_userresponceapi/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            setSuccessMessage(response.data);
            if (state.data.length >= experimentCompleted.count) {
                navigate('/part_4/perceived_negative_evaluations', { replace: true })
            } else {
                alert('Please fill all the fields.');
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                postSubmission();
            }
            else {
                setErrorMessage('Please check Part 4 Experiment Completed Userresponse Api');
                ErrorLogging('HTTP POST Part 4 /experiment_completed_userresponceapi/userId' + error);
                handle();

            }

        }

    };

    const saveDraft = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "data": state.data,
                "saveasdraft": "true"
            });
            const response = await axios.post(BASE_URL + `experiment_completed_userresponceapi/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            if (response.data.status === true) {
                setSuccessMessage('Saved As Draft');
                handleShow();
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                saveDraft();
            }
            else {
                setErrorMessage('Please check Part 4 Experiment Completed Userresponse Api');
                ErrorLogging('HTTP POST Part 4 /experiment_completed_userresponceapi/userId' + error);
                handle();
            }

        }
    };

    const nextPage = () => {
        navigate('/part_4/perceived_negative_evaluations', { replace: true })
    };

    const previousPage = () => {

        navigate(`/part_4/the_experiment`, { replace: true })

    };
    // close modal on its own

    setTimeout(handleClose, 1000);

    /*******   Hooks  *******/

    useEffect(() => {

        const arr = Array.from(state.data.filter((e) => e.user_responce === ''));
        const arr1 = Array.from(state.data.filter((e) => specialCharactersRegex.test(e.user_responce)));

        if (experimentCompleted !== undefined && experimentCompleted.length !== 0) {
            const { data = [] } = experimentCompleted.pred_prob_data;

            if (state.data.length >= experimentCompleted.count && arr.length === 0 && arr1.length === 0) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }, [state.data]);

    useEffect(() => {
        if (experimentCompleted.count === 0) {
            setDisabled(false);
        }
    }, [experimentCompleted]);

    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partFourEighthPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header /> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-12 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partFourEighthPage:title')}
                                        </div>
                                        {
                                            errorMessage ? (<div className="container mt-5">
                                                <div className='row justify-content-center'>
                                                    <div className="alert alert-danger text-center" role="alert">
                                                        {errorMessage}
                                                    </div>
                                                </div>
                                            </div>)
                                                :
                                                experimentCompleted !== undefined && experimentCompleted.length !== 0 ? (
                                                    <div>
                                                        {experimentCompleted.pred_prob_data.map((item, index) => {
                                                            const card = (
                                                                <div className='mb-3' key={index}>
                                                                    <div className="title mb-3">
                                                                        {item.statement}
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-lg-6 pr-3 pl-3'>
                                                                            <div className="textbox">
                                                                                <textarea
                                                                                    readOnly={item.disable_status}
                                                                                    className="form-control"
                                                                                    id="ControlTextarea"
                                                                                    rows="5"
                                                                                    defaultValue={item.user_responce}
                                                                                    placeholder={t('partFourEighthPage:placeholders')}
                                                                                    onChange={handleChange(item.id)}
                                                                                />
                                                                            </div>
                                                                            {textBoxValidation.id === item.id && (
                                                                                <small className="text-danger">
                                                                                    {textBoxValidation.errorMessage}
                                                                                </small>
                                                                            )}
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <p className='redText'>
                                                                            {t('partFourEighthPage:content.line1')}
                                                                            </p>
                                                                            <div dangerouslySetInnerHTML={{ __html: item.sample_answear }}>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                            return card;
                                                        })}
                                                    </div>
                                                )
                                                    :
                                                    <div className="loader-icon">
                                                        <TailSpin color="#4f5962" height={50} width={50} />
                                                    </div>
                                        }
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                                </div>
                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty mt-3"
                                            onClick={previousPage}

                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        {experimentCompleted !== undefined && experimentCompleted.length !== 0 && experimentCompleted.global_disable_status === false ? (
                                            <>
                                                <Button
                                                    className="draftButton"
                                                    onClick={saveDraft}
                                                >
                                                    {t('actions:save')}
                                                </Button>
                                            </>
                                        ) :
                                            <>
                                            </>
                                        }
                                        {experimentCompleted !== undefined && experimentCompleted.length !== 0 && experimentCompleted.global_disable_status === false ? (
                                            <>
                                                <span data-tip={
                                                    disabled === true ? textBoxValidation.disabledMessage : ""} data-for='toolTip'>
                                                    <Button
                                                        className="user-reponse-submit btn btn-primary link-sty mt-3"
                                                        onClick={postSubmission}
                                                        disabled={disabled}
                                                    >
                                                        {t('actions:continue')}
                                                    </Button>
                                                </span>
                                                <ReactTooltip id="toolTip" />
                                            </>
                                        ) :
                                            <>
                                                <Button
                                                    className="user-reponse-submit btn btn-primary link-sty mt-3"
                                                    onClick={nextPage}
                                                >
                                                   {t('actions:continue')}
                                                </Button>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose}>
                            {successMessage ?
                                <Modal.Body>{successMessage}</Modal.Body>
                                :
                                <Modal.Body>{errorMessage}</Modal.Body>
                            }
                        </Modal>
                        {/* error message modal */}

                        <Modal show={showError} >
                            <Modal.Body>{errorApiMessage}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={close}>
                                {t('actions:close')}
                                </Button>

                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4EighthPage;