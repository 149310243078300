import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import "../../../styles/adminlte.min.css";
import "../../../styles/dashboard-style.css";
import Header from "../../global/Header";
import Sidebar from "../../global/Sidebar";
import Footer from "../../global/Footer";
import FooterOfPrint from "../../global/FootorOfPrint";
import { useTranslation } from "react-i18next";

const ContentPage = ({ title, content, link, previousLink }) => {
  const { t } = useTranslation(["actions"]);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate(`/${link}`, { replace: true });
  };

  const previousPage = () => {
    navigate(`/${previousLink}`, { replace: true });
  };

  //print function
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;

    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    // document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title">{title}</div>
                    <div className="content">
                      <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>
                    {/* <FooterOfPrint/> */}
                  </div>
                </div>
                <div className="row">
                  <div className="previous col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={previousPage}
                    >
                      {t("previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={nextPage}
                    >
                      {t("continue")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentPage;
