import React, { useState, useEffect } from 'react';
import '../../../../styles/adminlte.min.css';
import { Button, InputGroup, Tooltip } from "react-bootstrap";
import ReactTooltip from 'react-tooltip';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import { useTranslation } from 'react-i18next';

// import FooterOfPrint from '../../../global/FootorOfPrint';


const ExcerciseAnswersHandout = ({ title, content, responses, link, type, sitnum, error }) => {
    const { t } = useTranslation(["handoutss"])

    let navigate = useNavigate();

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer">
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row content-with-sidebar-row m-0">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div id="main" className="col-md-10 content-wrapper content-div">
                        <div className="content">
                            <div className="row header-titlewith-text m-0">
                                <div className="title col-md-6 p-0">
                                    {title}
                                </div>
                                <div className="col-md-6 text-right p-0">
                                    <span> {t('handoutss:welcome')}</span> / {title}
                                </div>
                            </div>
                            <div className="content-topheads-text">
                                <div style={{ color: "red", fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: content }}></div>
                            </div>
                            {
                                error ? (<div className="container mt-5">
                                    <div className='row justify-content-center'>
                                        <div className="alert alert-danger text-center" role="alert">
                                            {error}
                                        </div>
                                    </div>
                                </div>)
                                    :
                                    responses !== undefined && responses.length !== 0 ? (
                                        <div className="content-wrap">
                                            <div className="content-topheads-text">
                                                <p className='redText'>{t('handoutss:situation')} {sitnum}</p>
                                                <p>{responses.Situation}</p>
                                                <div className='thinkingEx'>
                                                    {responses.Exerciseresults.map((item, index) => {
                                                        const renderSample = item.thinkingExcercise_id.sampleAnswear;
                                                        const card = (
                                                            <div className='row' key={index}>
                                                                <div className='col-lg-6'>
                                                                    <table className="thinking_table left_table" border="0"><tbody>
                                                                        <tr className="questions"><td>{t('handoutss:yourAns')}:<br />{item.thinkingExcercise_id.yourAnswear}
                                                                        </td></tr>
                                                                        <tr className="answers"><td>
                                                                            {item.userResponce}
                                                                        </td></tr>
                                                                    </tbody></table>
                                                                </div><div className='col-lg-6'>
                                                                    <table className="thinking_table left_table" border="0"><tbody>
                                                                        <tr className="questions"><td>
                                                                            {index === 0 && (
                                                                                <>
                                                                                    {t('handoutss:ourSAMPLE')}
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                            {item.thinkingExcercise_id.ourSampleanswear}</td></tr>
                                                                        <tr className="answers"><td>
                                                                            <div dangerouslySetInnerHTML={{ __html: renderSample }}></div>
                                                                        </td></tr>
                                                                    </tbody></table>
                                                                </div>
                                                            </div>
                                                        )
                                                        return card;
                                                    })}
                                                </div>
                                            </div>
                                        </div>)
                                        :
                                        <div className="loader-icon">
                                            <TailSpin color="#4f5962" height={50} width={50} />
                                        </div>
                            }
                        </div>
                        {/* <div>
                            <FooterOfPrint />
                        </div> */}
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );

};

export default ExcerciseAnswersHandout;