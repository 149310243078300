import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useTranslation } from "react-i18next";

const ChallengeExample = () => {
  const { t } = useTranslation(["partTwoChallengeExample", "actions"]);

  const moduleName = localStorage.setItem("moduleName", "Part 2");
  const part2Accessed = localStorage.setItem("part2Accessed", "true");

  // ============Local Storage ==========================

  const userInfo = localStorage.getItem('user');
  const user = JSON.parse(userInfo);
  const languageCode = user.userInfo.languageCode;

  /*******   Page Params  *******/

  const title = t("partTwoChallengeExample:title");

  const content = `<div>${t("partTwoChallengeExample:description")}</div>`;

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate("/part_2/challenge_1", { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_1/complete`, { replace: true });
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partTwoChallengeExample:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title">{title}</div>
                    <div className="content">
                      <div className="welcome-content">
                        <div className="content part-two-challenge">
                          <div className="img-how-to-sty ">
                            <img
                              src={`${DOMAIN_NAME}images/${languageCode}/part2/part2-welcome.png`}
                              alt="welcome-part-2"
                            />
                          </div>
                          <p>
                            <span className="redText">
                              {t("partTwoChallengeExample:content.line1")}
                            </span>
                          </p>
                          <h4>{t("partTwoChallengeExample:content.line2")}</h4>

                          <table className="challenge_table" cellPadding={30}>
                            <tbody>
                              <tr className="questions">
                                <td>
                                  {t("partTwoChallengeExample:content.line3")}
                                </td>
                              </tr>
                              <tr className="answers">
                                <td>
                                  {t("partTwoChallengeExample:content.line4")}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="challenge_table" cellPadding={30}>
                            <tbody>
                              <tr className="questions">
                                <td>
                                  {t("partTwoChallengeExample:content.line5")}
                                </td>
                              </tr>
                              <tr className="answers">
                                <td>
                                  {t("partTwoChallengeExample:content.line6")}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="challenge_table" cellPadding={30}>
                            <tbody>
                              <tr className="questions">
                                <td>
                                  {t("partTwoChallengeExample:content.line7")}
                                </td>
                              </tr>
                              <tr className="answers">
                                <td>
                                  {t("partTwoChallengeExample:content.line8")}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="challenge_table" cellPadding={30}>
                            <tbody>
                              <tr className="questions">
                                <td>
                                  {t("partTwoChallengeExample:content.line9")}
                                </td>
                              </tr>
                              <tr className="answers">
                                <td>
                                  {t("partTwoChallengeExample:content.line10")}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="challenge_table" cellPadding={30}>
                            <tbody>
                              <tr className="questions">
                                <td>
                                  {t("partTwoChallengeExample:content.line11")}
                                </td>
                              </tr>
                              <tr className="answers">
                                <td>
                                  {t("partTwoChallengeExample:content.line12")}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="challenge_table" cellPadding={30}>
                            <tbody>
                              <tr className="questions">
                                <td>
                                  {t("partTwoChallengeExample:content.line13")}
                                </td>
                              </tr>
                              <tr className="answers">
                                <td>
                                  {t("partTwoChallengeExample:content.line14")}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="challenge_table" cellPadding={30}>
                            <tbody>
                              <tr className="questions">
                                <td>
                                  {t("partTwoChallengeExample:content.line15")}
                                </td>
                              </tr>
                              <tr className="answers">
                                <td>
                                  {t("partTwoChallengeExample:content.line16")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className="challenge_table" cellPadding={30}>
                            <tbody>
                              <tr className="questions">
                                <td>
                                  {t("partTwoChallengeExample:content.line17")}
                                </td>
                              </tr>
                              <tr className="answers">
                                <td>
                                  {t("partTwoChallengeExample:content.line18")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                </div>
                <div className="row">
                  <div className="previous col-5">
                    <Button className="link-sty mb-3" onClick={previousPage}>
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-7">
                    <Button className="link-sty mb-3" onClick={nextPage}>
                      {t("actions:continue")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeExample;
