import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { useTranslation } from "react-i18next";
// import introImage from '../../../../public/images/part3/Part3-Welcome_edites.png'
// import benModel from '../../../../public/images/part3/social_model_ben_edited.png'

const Part3Reached = () => {
  const { t } = useTranslation(["actions", "partThreeReached"]);
  const moduleName = localStorage.setItem("moduleName", "Part 3");
  const part3Accessed = localStorage.setItem("part3Accessed", "true");

   // ============Local Storage ==========================

const userInfo = localStorage.getItem('user'); const user = JSON.parse(userInfo);
const languageCode =user.userInfo.languageCode;

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate("/part_3/bens_model", { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_2/challenge_5_sample_answers`, { replace: true });
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partThreeReached:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">
                      {t("partThreeReached:title")}
                    </div>
                    <div className="img-how-to-sty centre-align">
                      {/* <img src={`${DOMAIN_NAME}images/part3/part3-welcome.png`} alt='welcome-to-part-3' /> */}
                      <img
                        className="part3_intro_image"
                        src={`${DOMAIN_NAME}images/${languageCode}/part3/Part3-Welcome_edites.png`}
                        alt={t("partThreeReached:alt.text1")}
                      />
                      {/* <img className='part3_intro_image' src={introImage} alt='welcome-to-part-3' /> */}
                    </div>
                    <p>{t("partThreeReached:content.line1")}</p>
                    <p className="redText">
                      {" "}
                      {t("partThreeReached:content.line2")}
                    </p>
                    <p>{t("partThreeReached:content.line3")}</p>
                    <div className="img-how-to-sty centre-align">
                      {/* <img src={`${DOMAIN_NAME}images/part3/ben_social_model.png`} alt="ben-social-model" /> */}
                      <img
                        className="part3_benmodel_image"
                        src={`${DOMAIN_NAME}images/${languageCode}/part3/social_model_ben_edited.png`}
                        alt={t("partThreeReached:alt.text2")}
                      />
                      {/* <img className='part3_benmodel_image' src={benModel} alt="ben-social-model" /> */}
                    </div>
                  </div>
                  {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                </div>
                <div className="row">
                  <div className="previous1 col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={previousPage}
                    >
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={nextPage}
                    >
                      {t("actions:continue")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part3Reached;
