import React from 'react';
import { Helmet } from 'react-helmet';
import ChallengeAnswersHandout from '../templates/ChallengeAnswersHandout';
import { GetChallengeResponse } from '../../../../services/part2/get-challenge-response';
import { useTranslation } from 'react-i18next';


const Handout2 = () => {
        const { t } = useTranslation(["handoutss"])
    
    const [challengeResponse, errorMessage] = GetChallengeResponse('1');

    const title = "Handout - Challenging Thinking";

    const content = "lorem ipsum";
    
    return(
        <div  className='welcome-content'>
            <Helmet>
                <title>{t('handoutss:handout2.helmet')}</title>
            </Helmet>
            <ChallengeAnswersHandout 
                title={t('handoutss:handout2.title')}
                content={t('handoutss:handout2.content')}
                responses={challengeResponse}
                link='part_2/challenge_2'
                error={errorMessage}
            />
        </div>        
    );

};

export default Handout2;