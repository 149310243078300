import React, { useState, useEffect } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";

import { BASE_URL } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import RefreshToken from "../../../../services/refresh-token";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import { GetAvoidanceUtbasQuestionnaire } from "../../../../services/part3/get-avoidance-utbas-questionnaire";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { useTranslation } from "react-i18next";

const YourModel = () => {
  const { t } = useTranslation(["actions", "partThreeYourModel"]);

  const [avoidanceQuestionnaire, avoidanceErrorMessage] =
    GetAvoidanceUtbasQuestionnaire("Avoidance"); // Get Avoidance Questionnaire from api
  const [utbasQuestionnaire, utbasErrorMessage] =
    GetAvoidanceUtbasQuestionnaire("UTBAS"); // Get UTBAS Questionnaire from api

  /*******   States *******/

  const [avoidanceState, setAvoidanceState] = useState({});
  const [utbasState, setutbasState] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [showMore, setShowMore] = useState({
    avoidance: false,
    utbas: false,
  });

  console.log(utbasQuestionnaire,"UTBAS")

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  const numberOfAvoidance = showMore.avoidance
    ? avoidanceQuestionnaire.questionnairs.length
    : 10;
  const numberOfUtbas = showMore.utbas
    ? utbasQuestionnaire.questionnairs.length
    : 10;

  /*******   Helper Functions  *******/
  let navigate = useNavigate();

  const handleChangeAvoidance = (e) => {
    const { name, value } = e.target;
    setAvoidanceState({ question_id: value });
  };

  const handleChangeUtbas = (e) => {
    const { name, value } = e.target;
    setutbasState({ question_id: value });
  };

  const handleAvoidanceShowMore = () => {
    setShowMore({
      avoidance: true,
    });
  };

  const handleUtbasShowMore = () => {
    setShowMore({
      utbas: true,
    });
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user params
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        question_id: avoidanceState.question_id,
      });
      const response = await axios.post(
        BASE_URL + `create_situation/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            "languageCode": user.userInfo.languageCode,
          },
        }
      );
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }

    try {
      const params = JSON.stringify({
        question_id: utbasState.question_id,
      });
      const response = await axios.post(
        BASE_URL + `create_casual/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            "languageCode": user.userInfo.languageCode,
          },
        }
      );
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Part 3 Create Casual Api");
        ErrorLogging("HTTP POST Part 3 /create_casual/userId" + error);
        handle();
      }
    }

    if (disabled === false) {
      navigate("/part_3/your_model_of_social_anxiety", { replace: true });
    }
  };

  const previousPage = () => {
    navigate(`/part_3/self-focussed_imagery`, { replace: true });
  };

  /*******   Hooks  *******/

  useEffect(() => {
    if (
      Object.keys(avoidanceState).length === 0 ||
      Object.keys(utbasState).length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [avoidanceState, utbasState]);

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partThreeYourModel:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">
                      {t("partThreeYourModel:title")}
                    </div>
                    <div>
                      <div id="edit-feared-situation-text">
                        <p className="firstSec">
                          {t("partThreeYourModel:content.line1")}
                        </p>
                      </div>
                      <div id="edit-feared-situation">
                        {avoidanceErrorMessage ? (
                          <div className="container mt-5">
                            <div className="row justify-content-center">
                              <div
                                className="alert alert-danger text-center"
                                role="alert"
                              >
                                {avoidanceErrorMessage}
                              </div>
                            </div>
                          </div>
                        ) : avoidanceQuestionnaire !== undefined &&
                          avoidanceQuestionnaire.length !== 0 ? (
                          <>
                            <div className="content-wrap">
                              {avoidanceQuestionnaire.questionnairs
                                .slice(0, numberOfAvoidance)
                                .map((item, index) => {
                                  const card = (
                                    <div
                                      className="left-content checkbx-sty"
                                      key={index}
                                    >
                                      <InputGroup.Checkbox
                                        defaultChecked={item.responce}
                                        disabled={item.disable_status}
                                        className="radio-size"
                                        type="radio"
                                        name="avoidance"
                                        value={item._id}
                                        onChange={handleChangeAvoidance}
                                      />
                                      {item.question}
                                    </div>
                                  );
                                  return card;
                                })}
                            </div>
                            <Button
                              className="loadmoreBtn"
                              onClick={handleAvoidanceShowMore}
                            >
                              {showMore.avoidance ? (
                                ""
                              ) : (
                                <>{t("partThreeYourModel:content.line2")}</>
                              )}
                            </Button>
                          </>
                        ) : (
                          <div className="loader-icon">
                            <TailSpin color="#4f5962" height={50} width={50} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div id="edit-reason-for-avoidance-text">
                        <p className="secondSec">
                          {t("partThreeYourModel:content.line3")}
                        </p>
                      </div>
                      <div id="edit-reason-for-avoidance">
                        {utbasErrorMessage ? (
                          <div className="container mt-5">
                            <div className="row justify-content-center">
                              <div
                                className="alert alert-danger text-center"
                                role="alert"
                              >
                                {utbasErrorMessage}
                              </div>
                            </div>
                          </div>
                        ) : utbasQuestionnaire !== undefined &&
                          utbasQuestionnaire.length !== 0 ? (
                          <>
                            <div className="content-wrap">
                              {utbasQuestionnaire.questionnairs
                                .slice(0, numberOfUtbas)
                                .map((item, index) => {
                                  const card = (
                                    <div
                                      className="left-content checkbx-sty"
                                      key={index}
                                    >
                                      <InputGroup.Checkbox
                                        defaultChecked={item.responce}
                                        disabled={item.disable_status}
                                        className="radio-size"
                                        type="radio"
                                        value={item._id}
                                        name="utbas"
                                        onChange={handleChangeUtbas}
                                      />
                                      {item.question}
                                    </div>
                                  );
                                  return card;
                                })}
                            </div>
                            <Button
                              className="loadmoreBtn"
                              onClick={handleUtbasShowMore}
                            >
                              {showMore.utbas ? (
                                ""
                              ) : (
                                <>{t("partThreeYourModel:content.line4")}</>
                              )}
                            </Button>
                          </>
                        ) : (
                          <div className="loader-icon">
                            <TailSpin color="#4f5962" height={50} width={50} />
                          </div>
                        )}
                      </div>
                      <p></p>
                    </div>
                  </div>
                  {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                </div>

                <span
                  data-tip={
                    disabled === true
                      ? t('partThreeYourModel:toolTipText')
                      : ""
                  }
                  data-for="toolTip"
                >
                  <div className="row">
                    <div className="previous1 col-6">
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty"
                        onClick={previousPage}
                      >
                        {t("actions:previous")}
                      </Button>
                    </div>
                    <div className="next col-6">
                      <Button
                        className="user-reponse-submit btn btn-primary link-sty"
                        onClick={postSubmission}
                        disabled={disabled}
                      >
                        {t("actions:continue")}
                      </Button>
                    </div>
                  </div>
                </span>
                <ReactTooltip id="toolTip" />
              </div>
            </div>
          </div>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default YourModel;
