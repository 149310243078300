import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import { GetEssayResponse } from "../../../../services/part6/user-essay-response";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useTranslation } from "react-i18next";
// import FooterOfPrint from '../../../global/FootorOfPrint';

const Handout5 = () => {
  const { t } = useTranslation(["handout5"]);
  let navigate = useNavigate();

  const [essayResponse, errorMessage] = GetEssayResponse();

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("handout5:title")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div
            id="main"
            className="col-md-12 content-widthsidebar content-wrapper"
          >
            <div className="card">
              <div className="card-body">
                <div className="title mb-3">{t("handout5:helmet")}</div>
                <p className="redText">{t("handout5:description.line1")}</p>
                {essayResponse.length !== 0 && (
                  <p>{essayResponse.eassay_responce.eassy}</p>
                )}

                <p className="redText">{t("handout5:description.line3")}</p>
                <p> {t("handout5:description.line4")}</p>
                <p> {t("handout5:description.line5")}</p>
                <p>{t("handout5:description.line6")}</p>
                <p>{t("handout5:description.line7")}</p>
                <p>{t("handout5:description.line8")}</p>
                <p>{t("handout5:description.line9")}</p>
                <p>{t("handout5:description.line10")}</p>
                <p>{t("handout5:description.line11")}</p>
              </div>
            </div>
            {/* <div>
                            <FooterOfPrint/>
                        </div> */}
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Handout5;
