import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import { GetAllTyrannyStatementResponse } from "../../../../services/part5/tyranny-statements-response";
import ModuleCompletionEmails from "../../../../services/module-completion-emails";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { useTranslation } from "react-i18next";

const Part5Fifth = () => {
  const { t } = useTranslation(["partFiveFifth", "actions"]);

  const [tyrannyShouldsResponse, errorMessage] =
    GetAllTyrannyStatementResponse(); // Get Tyranny Should Response frpm api

  /*******   States  *******/

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);
  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate("/part_6/the_problem_of_self-conciousness", { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_5/advantages_and_disadvantages_of_shoulds`, {
      replace: true,
    });
  };

  /*******   Hooks  *******/
  useEffect(() => {
    ModuleCompletionEmails("sendmailPart5");
  }, []);

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partFiveFifth:helemt")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">{t("partFiveFifth:title")}</div>
                    <p className="redText">
                      {t("partFiveFifth:content.line1")}
                    </p>
                    {errorMessage ? (
                      <div className="container mt-5">
                        <div className="row justify-content-center">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {errorMessage}
                          </div>
                        </div>
                      </div>
                    ) : tyrannyShouldsResponse !== undefined &&
                      tyrannyShouldsResponse.length !== 0 ? (
                      <div className="content-wrap">
                        <div className="content-topheads-text">
                          <div className="thinkingEx">
                            {tyrannyShouldsResponse.responce.map(
                              (item, index) => {
                                const card = (
                                  <div className="sampleAnswers" key={index}>
                                    <p className="redText mt-3 mb-3 help-text">
                                      {item.question_id.question}
                                    </p>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <table
                                          className="thinking_table left_table"
                                          border="0"
                                        >
                                          <tbody>
                                            <tr className="questions">
                                              <td>
                                                {t(
                                                  "partFiveFifth:content.line2"
                                                )}
                                                <br />
                                              </td>
                                            </tr>
                                            <tr className="answers">
                                              <td>
                                                {item.advantage_userresponce}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          className="thinking_table left_table"
                                          border="0"
                                        >
                                          <tbody>
                                            <tr className="questions mt-3 mb-3">
                                              <td>
                                                {t(
                                                  "partFiveFifth:content.line3"
                                                )}
                                                <br />
                                              </td>
                                            </tr>
                                            <tr className="answers">
                                              <td>
                                                {item.disadvantage_userresponce}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="col-lg-6">
                                        <table
                                          className="thinking_table left_table"
                                          border="0"
                                        >
                                          <tbody>
                                            <tr className="questions">
                                              <td>
                                                {t(
                                                  "partFiveFifth:content.line4"
                                                )}
                                              </td>
                                            </tr>
                                            <tr className="answers">
                                              <td>
                                                <div>
                                                  {item.question_id.advantage}
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          className="thinking_table left_table"
                                          border="0"
                                        >
                                          <tbody>
                                            <tr className="questions">
                                              <td>
                                                {t(
                                                  "partFiveFifth:content.line5"
                                                )}
                                              </td>
                                            </tr>
                                            <tr className="answers">
                                              <td>
                                                <div>
                                                  {
                                                    item.question_id
                                                      .disadvantage
                                                  }
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                );
                                return card;
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="loader-icon">
                        <TailSpin color="#4f5962" height={50} width={50} />
                      </div>
                    )}
                  </div>
                  {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                </div>

                <div className="row">
                  <div className="previous1 col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={previousPage}
                    >
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={nextPage}
                    >
                      {t("actions:continue")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part5Fifth;
