import React from "react";
import { Helmet } from "react-helmet";
import ContentPage from "../ContentPages";
import { useTranslation } from "react-i18next";
import { DOMAIN_NAME } from "../../../../env/Baseurl";

const Begin = () => {
  const { t } = useTranslation(["partOneBegin"]);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const moduleName = localStorage.setItem("moduleName", "Part 1");
  const part1Accessed = localStorage.setItem("part1Accessed", "true");
  const languageCode = user.userInfo.languageCode;


  /*******   Page Params  *******/

  const title = t("title", {
    userName: user.userInfo.user_name,
  });
  const content = `<div>
    <div className="img-how-to-sty " style="
    text-align: center;">
                            <img
                              src="${DOMAIN_NAME}images/${languageCode}/part1/Part_1_Welcome.png"
                              alt="welcome-part-1"                         
                            />
                          </div>
  <div>
  <p>${t("description.line1")}</p><p>${t(
    "description.line2"
  )}</p><p>${t("description.line3")}</p><h4 className='redText'>${t(
    "description.line4"
  )}</h4><p className='redText'>${t(
    "description.line5"
  )}</p><ul class='textUl'><li>${t("description.line6")}</li><li>${t(
    "description.line7"
  )}</li><li>${t("description.line8")}</li><li>${t(
    "description.line9"
  )}</li><li>${t("description.line10")}</li><li><p>${t(
    "description.line11"
  )}</p>${t("description.line12")}</li><li>${t(
    "description.line13"
  )}</li></ul></div></div>`;

  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title >${t("helmet")}</title>
      </Helmet>
      <ContentPage
        title={title}
        content={content}
        link="part_1/waiting_for_the_bus"
        previousLink="intro/dass_questionnaire"
      />
    </div>
  );
};

export default Begin;
