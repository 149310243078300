import React, { useState, useEffect } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import RefreshToken from "../../../../services/refresh-token";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import { GetAllTyrannyShoulds } from "../../../../services/part5/get-all-tyranny-of-shoulds.js";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { useTranslation } from "react-i18next";

const Part5Second = () => {
  const { t } = useTranslation(["partFiveSecond", "actions"]);

  const [tyrannyShoulds, errorMessage] = GetAllTyrannyShoulds(); // Get Tyranny Should Questions from Api

  /*******   States *******/

  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorApiMessage, setErrorMessage] = useState("");

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (state.some((item) => item.question_id === name)) {
      setState(state.filter((state) => state.question_id !== name));
    } else {
      setState([...state, { question_id: name }]);
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state,
      });
      const response = await axios.post(
        BASE_URL + `tyranny_of_the_Shoulds_useresponce/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            languageCode: user.userInfo.languageCode,
          },
        }
      );
      setSuccessMessage(response.data);
      if (state.length === 3) {
        navigate("/part_5/what_do_my_shoulds_do_for_me", { replace: true });
      } else {
        alert("Please select the 3 that are most relevant to you.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    navigate("/part_5/what_do_my_shoulds_do_for_me", { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_5/should_statements`, { replace: true });
  };

  /*******   Hooks  *******/

  useEffect(() => {
    if (state.length === 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [state]);

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> {t("partFiveSecond:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">
                      {t("partFiveSecond:title")}
                    </div>
                    <b>{t("partFiveSecond:content.line1")}</b>
                    <div className="col-lg-6">
                      {errorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {errorMessage}
                            </div>
                          </div>
                        </div>
                      ) : tyrannyShoulds !== undefined &&
                        tyrannyShoulds.length !== 0 ? (
                        <div className="content-wrap">
                          {tyrannyShoulds.questions.map((item, index) => {
                            const card = (
                              <div
                                className="left-content checkbx-sty"
                                key={index}
                              >
                                <InputGroup.Checkbox
                                  defaultChecked={item.checkbox_status}
                                  disabled={item.disable_status}
                                  aria-label="checkbox 1"
                                  value={item._id}
                                  name={item._id}
                                  selected={
                                    Boolean(state[item.question]) &&
                                    state[item.question] === item.question
                                  }
                                  onChange={handleChange}
                                />
                                {item.question}
                              </div>
                            );
                            return card;
                          })}
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                </div>

                <div className="row">
                  <div className="previous1 col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty mt-3"
                      onClick={previousPage}
                    >
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    {tyrannyShoulds !== undefined &&
                    tyrannyShoulds.length !== 0 &&
                    tyrannyShoulds.global_disable_status === false ? (
                      <>
                        <span
                          data-tip={
                            disabled === true
                              ? t("partFiveSecond:toolTipText")
                              : ""
                          }
                          data-for="toolTip"
                        >
                          <Button
                            className="user-reponse-submit btn btn-primary link-sty mt-3"
                            onClick={postSubmission}
                            disabled={disabled}
                          >
                            {t("actions:continue")}
                          </Button>
                        </span>
                        <ReactTooltip id="toolTip" />
                      </>
                    ) : (
                      <>
                        <Button
                          className="user-reponse-submit btn btn-primary link-sty mt-3"
                          onClick={nextPage}
                        >
                          {t("actions:continue")}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{errorApiMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                {t("actions:close")}
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part5Second;
