import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import audio1 from "../../../../Audio/attentionHandout1.mp3";
import castilian_audio from "../../../../Audio/attention_castilian.mp3";
import latin_audio from "../../../../Audio/attention_latin.mp3";
import audio2 from "../../../../Audio/rescript.mp3";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useTranslation } from "react-i18next";
// import FooterOfPrint from '../../../global/FootorOfPrint';

const Handout6 = () => {
  let { t } = useTranslation(["handout6"]);
  let navigate = useNavigate();
  const userInfo = localStorage.getItem('user');
  const user = JSON.parse(userInfo);
  const languageCode = user.userInfo.languageCode;

  const audioSource = languageCode === 'en'
    ? audio1
    : languageCode === 'es'
      ? castilian_audio
      : latin_audio;

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("handout6:title")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div
            id="main"
            className="col-md-12 content-widthsidebar content-wrapper"
          >
            <div className="card">
              <div className="card-body">
                <div className="title mb-3">{t("handout6:helmet")}</div>
                <p>{t("handout6:description.line1")}</p>
                <p>
                  <a href={audioSource} target="_blank" rel="noreferrer">
                    {t("handout6:description.line2")}
                  </a>
                </p>
                <p>{t("handout6:description.line3")}</p>
                <p>
                  <a href={audio2} rel="noreferrer">
                    {t("handout6:description.line4")}
                  </a>
                </p>
              </div>
            </div>
            {/* <div>
                            <FooterOfPrint />
                        </div> */}
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Handout6;
