import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { TailSpin } from "react-loader-spinner";

import axios from "axios";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../../../../env/Baseurl";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import RefreshToken from "../../../../services/refresh-token";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import SelectOptions from "../../../../constants/part1/WhatsCasualThought";
import { GetCasualThoughtResponse } from "../../../../services/part1/get-casual-thought-response";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useTranslation } from "react-i18next";
// import casualParkImage from '../../../../public/images/part1/Caual_park_edited.png'

const WhatsCasualThought = () => {
  const { t } = useTranslation(["partOneWhatsCasualThought", "actions"]);

  const { selectOptions1, selectOptions2} = SelectOptions();

  const [casualThoughtResponse, responseErrorMessage] =
    GetCasualThoughtResponse();

    // ============Local Storage ==========================

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);
    const languageCode = user.userInfo.languageCode;


  /*******   States *******/

  const [state, setState] = useState({ data: [] });
  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [options, setOptions] = useState({
    one: "",
    two: "",
  });

  /*******   Page Params  *******/

  const title = t("partOneWhatsCasualThought:title");

  const content = `<div>${t("partOneWhatsCasualThought:description")}</div>`;

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const selectHandler = (name, value, ques, label) => {
    setOptions({ ...options, [name]: value });

    if (state.data.some((item) => item.question === ques)) {
      // Checking Question ID in state on change

      let updatedData = state.data.map((item) => {
        if (item.question === ques) {
          return {
            ...item,
            question: ques,
            userResponce: label,
            quesindex: name,
          }; // Updating user selection in state
        }
        return item;
      });

      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [
          ...state.data,
          { question: ques, userResponce: label, quesindex: name },
        ], // Adding new question response in state
      }));
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user params
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
      });
      const response = await axios.post(
        BASE_URL + `casual_thoughts_create/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            "languageCode": user.userInfo.languageCode,
          },
        }
      );
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage(t("partOneWhatsCasualThought:setErrorMessage"));
        ErrorLogging("HTTP POST /casual_thoughts_create/userId" + error);
      }
    }

    navigate("/part_1/causal_thoughts", { replace: true });
  };

  const nextPage = () => {
    navigate("/part_1/causal_thoughts", { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_1/thinking_exercise_2_sample_answers`, { replace: true });
  };
  /*******   Hooks  *******/

  useEffect(() => {
    let isEmpty = Object.values(options).every((x) => x !== ""); // Checking if there is empty response
    setDisabled(!isEmpty);
  }, [options]);

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partOneWhatsCasualThought:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">{title}</div>
                    <div className="welcome-content">
                      <div className="content">
                        <p>{t("partOneWhatsCasualThought:content.line1")}</p>
                        <p>{t("partOneWhatsCasualThought:content.line2")}</p>
                      </div>
                      <div className="img-how-to-sty m-3">
                        {/* <img src={`${DOMAIN_NAME}images/part1/causal_park.png`} alt="casual-park" /> */}
                        <img
                          className="part1_park_image"
                          src={`${DOMAIN_NAME}images/${languageCode}/part1/Caual_park_edited.png`}
                          alt="casual-park"
                        />
                        {/* <img className='part1_park_image' style={{ width: '750px', height: 'auto' }} src={casualParkImage} alt="casual-park" /> */}
                      </div>
                      {responseErrorMessage ? (
                        <div className="container mt-5">
                          <div className="row justify-content-center">
                            <div
                              className="alert alert-danger text-center"
                              role="alert"
                            >
                              {responseErrorMessage}
                            </div>
                          </div>
                        </div>
                      ) : casualThoughtResponse !== undefined &&
                        casualThoughtResponse.length !== 0 ? (
                        <div className="row">
                          <div className="col-lg-3"></div>
                          <div className="col-lg-3">
                            <p className="redText">
                              {t("partOneWhatsCasualThought:content.line3")}
                            </p>
                            <Select
                              placeholder={
                                casualThoughtResponse.global_disable_status ===
                                false
                                  ? t("partOneWhatsCasualThought:placeholders.line1")
                                  : casualThoughtResponse.array[0].userResponce
                              }
                              isDisabled={
                                casualThoughtResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions1}
                              onChange={(e) => {
                                selectHandler(
                                  "one",
                                  e.value,
                                  "How do you think Karen feels as a result of her causal thought?",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.one,
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="col-lg-3">
                            <p className="redText" id="text">
                              {t("partOneWhatsCasualThought:content.line4")}
                            </p>
                            <Select
                              placeholder={
                                casualThoughtResponse.global_disable_status ===
                                false
                                  ? t("partOneWhatsCasualThought:placeholders.line2")
                                  : casualThoughtResponse.array[1].userResponce
                              }
                              isDisabled={
                                casualThoughtResponse.global_disable_status ===
                                false
                                  ? false
                                  : true
                              }
                              options={selectOptions2}
                              onChange={(e) => {
                                selectHandler(
                                  "two",
                                  e.value,
                                  "How do you think Ben feels as a result of his causal thought?",
                                  e.label
                                );
                              }}
                            />
                            <p className="selected-txt-sty">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: options.two,
                                }}
                              ></span>
                            </p>
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                      ) : (
                        <div className="loader-icon">
                          <TailSpin color="#4f5962" height={50} width={50} />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                </div>
                <div className="row">
                  <div className="previous1 col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={previousPage}
                    >
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    {casualThoughtResponse !== undefined &&
                    casualThoughtResponse.length !== 0 &&
                    casualThoughtResponse.global_disable_status === false ? (
                      <>
                        <span
                          data-tip={
                            disabled === true
                              ? t('partOneWhatsCasualThought:toolTipText')
                              : ""
                          }
                          data-for="toolTip"
                        >
                          <Button
                            className="link-sty mb-3"
                            onClick={postSubmission}
                            disabled={disabled}
                          >
                            {t("actions:continue")}
                          </Button>
                        </span>
                        <ReactTooltip id="toolTip" />
                      </>
                    ) : (
                      <>
                        <Button
                          className="user-reponse-submit"
                          onClick={nextPage}
                        >
                          {t("actions:continue")}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsCasualThought;
