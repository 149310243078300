import React, { useState, useEffect } from 'react';
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { BASE_URL } from '../../../../env/Baseurl';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import ErrorLogging from '../../../../services/error-logs';
import RefreshToken from '../../../../services/refresh-token';
import { GetAvoidanceQuestions } from '../../../../services/part6/get-avoidance-questions';
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';
// import bar1 from '../../../../public/images/part6/bar1_edited.png'
// import bar2 from '../../../../public/images/part6/bar2_edited.png'


const Part6ThirdPage = () => {

    const { t } = useTranslation(["partSixThirdPage", "actions"])

    const [avoidanceQuestions, errorMessage] = GetAvoidanceQuestions();         // get Avoidance Questions from API

    // ============Local Storage ==========================

    const userInfo = localStorage.getItem('user'); 
    const user = JSON.parse(userInfo);
    const languageCode =user.userInfo.languageCode;


    /*******   States *******/

    const [state, setState] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [apiErrorMessage, setErrorMessage] = useState('');
    const [disabled, setDisabled] = useState(true);
    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);

    /*******  Helper Functions and variables  *******/

    let navigate = useNavigate();

    const handleChange = (e) => {

        const { name, value } = e.target;

        if (state.some(item => item.question_id === name)) {

            setState(
                state.filter(state => state.question_id !== name)
            );

        } else {
            setState([...state, { "question_id": name }]
            );
        }

        if (state.length === 0) {
            setDisabled(true);

        }

    };

    const postSubmission = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "data": state
            });
            const response = await axios.post(BASE_URL + `sra_fill_avoidance/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            setSuccessMessage(response.data);
            if (state.length === 3) {
                navigate('/part_6/sar_responses', { replace: true })

            } else {
                alert('Please select atleast one of the options.');
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                postSubmission();
            } else {
                setErrorMessage('Please check Create Questionnaire Api');
                ErrorLogging('HTTP POST /createQuestionnairs_type/userId' + error);
                handle();
            }

        }
    };

    const nextPage = () => {
        navigate('/part_6/sar_responses', { replace: true })
    };

    const previousPage = () => {
        navigate(`/part_6/att`, { replace: true })

    };


    /*******  Hooks  *******/

    useEffect(() => {

        if (state.length === 3) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [state]);

    /*******  Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partSixThirdPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-12 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partSixThirdPage:title')}
                                        </div>
                                        <p>
                                        {t('partSixThirdPage:content.line1')}
                                        </p>
                                        <p>
                                        {t('partSixThirdPage:content.line2')}
                                        </p>
                                        <div className='img-how-to-sty centre-align'>
                                            {/* <img src={`${DOMAIN_NAME}images/part6/bar1.jpeg`} alt="bar-1" /> */}
                                            <img className='part6_bar_image' src={`${DOMAIN_NAME}images/${languageCode}/part6/bar1_edited.png`} alt="bar-1" />
                                            {/* <img className='part6_bar_image' src={bar1} alt="bar-1" /> */}
                                        </div>
                                        <p>
                                        {t('partSixThirdPage:content.line3')}
                                        </p>
                                        <p>
                                        {t('partSixThirdPage:content.line4')}
                                        </p>
                                        <p>
                                        {t('partSixThirdPage:content.line5')}
                                        </p>
                                        <div className='img-how-to-sty centre-align'>
                                            {/* <img src={`${DOMAIN_NAME}images/part6/bar2.jpeg`} alt="bar-2" /> */}
                                            <img className='part6_bar_image' src={`${DOMAIN_NAME}images/${languageCode}/part6/bar2_edited.png`} alt="bar-2" />
                                            {/* <img className='part6_bar_image' src={bar2} alt="bar-2" /> */}
                                        </div>
                                        <p>
                                        {t('partSixThirdPage:content.line6')}
                                        </p>
                                        {
                                            errorMessage ? (<div className="container mt-5">
                                                <div className='row justify-content-center'>
                                                    <div className="alert alert-danger text-center" role="alert">
                                                        {errorMessage}
                                                    </div>
                                                </div>
                                            </div>)
                                                :
                                                avoidanceQuestions !== undefined && avoidanceQuestions.length !== 0 ? (
                                                    <div className="content-wrap">
                                                        {avoidanceQuestions.avoidance_data.map((item, index) => {
                                                            const card = (
                                                                <div className="left-content checkbx-sty" key={index}>
                                                                    <InputGroup.Checkbox
                                                                        defaultChecked={item.checkbox_status}
                                                                        disabled={item.disable_status}
                                                                        aria-label="checkbox 1"
                                                                        value={item.question}
                                                                        name={item._id}
                                                                        selected={
                                                                            Boolean(state[item.question]) &&
                                                                            state[item.question] === item.question
                                                                        }
                                                                        onChange={handleChange}
                                                                    />
                                                                    {item.question}
                                                                </div>
                                                            )
                                                            return card;
                                                        })}
                                                    </div>
                                                )
                                                    :
                                                    <div className="loader-icon">
                                                        <TailSpin color="#4f5962" height={50} width={50} />
                                                    </div>
                                        }

                                        <p>
                                            <br />
                                            <span className='redText'>{t('partSixThirdPage:content.line7')}</span> {t('partSixThirdPage:content.line8')}
                                        </p>

                                        {/* </div>
                                    <div>
                                        <FooterOfPrint/> */}
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}
                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        {avoidanceQuestions !== undefined && avoidanceQuestions.length !== 0 && avoidanceQuestions.global_disable_status === false ? (
                                            <>
                                                <span data-tip={disabled === true ? t('partSixThirdPage:toolTipText') : ""} data-for='toolTip'>
                                                    <Button
                                                        className="user-reponse-submit btn btn-primary link-sty"
                                                        onClick={postSubmission}
                                                        disabled={disabled}
                                                    >
                                                        {t('actions:continue')}
                                                    </Button>
                                                </span>
                                                <ReactTooltip id="toolTip" />
                                            </>
                                        ) :
                                            <>
                                                <Button
                                                    className="user-reponse-submit btn btn-primary link-sty"
                                                    onClick={nextPage}
                                                >
                                                    {t('actions:continue')}
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* error message modal */}

                    <Modal show={showError} >
                        <Modal.Body>{apiErrorMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                            {t('actions:close')}
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>

    );
};

export default Part6ThirdPage;