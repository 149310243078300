import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { useTranslation } from "react-i18next";
// import susan3Image from '../../../../public/images/part3/Susan3_edited1.png'

const LetsRecap = () => {
  const { t } = useTranslation(["action", "partThreeLetsRecap"]);

  // ============Local Storage ==========================

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const languageCode = user.userInfo.languageCode;

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate("/part_3/causal_thoughts_and_safety_behaviours", {
      replace: true,
    });
  };

  const previousPage = () => {
    navigate(`/part_3/another_example_of_safety_behaviours`, { replace: true });
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partThreeLetsRecap:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">
                      {t("partThreeLetsRecap:title")}
                    </div>
                    <div className="img-how-to-sty ">
                      {/* <img src={`${DOMAIN_NAME}images/part3/susan3.png`} alt="susan" /> */}
                      <img
                        className="part2_susan3_image"
                        src={`${DOMAIN_NAME}images/${languageCode}/part3/Susan3_edited1.png`}
                        alt={t("partThreeLetsRecap:imageAlt")}
                      />
                      {/* <img className='part2_susan3_image' src={susan3Image} alt="susan" /> */}
                      <p></p>
                    </div>
                    <p>{t("partThreeLetsRecap:content.line1")}</p>
                    <p>{t("partThreeLetsRecap:content.line2")}</p>
                    <p className="redText">
                      {t("partThreeLetsRecap:content.line3")}
                    </p>
                    <p>{t("partThreeLetsRecap:content.line4")}</p>
                    <p>{t("partThreeLetsRecap:content.line5")}</p>
                    <p className="redText">
                      {t("partThreeLetsRecap:content.line6")}
                    </p>
                    <p>
                      <span className="redText">Fjóla: </span>
                      {t("partThreeLetsRecap:content.line7")}
                    </p>
                    <p>
                      <span className="redText">Susan: </span>
                      {t("partThreeLetsRecap:content.line8")}
                    </p>
                    <p>
                      <span className="redText">Fjóla: </span>
                      {t("partThreeLetsRecap:content.line9")}{" "}
                      <span style={{ textDecoration: "underline" }}>
                        {t("partThreeLetsRecap:content.line10")}
                      </span>
                    </p>
                    <p>
                      <span className="redText">Susan:</span>{" "}
                      {t("partThreeLetsRecap:content.line11")}
                    </p>
                    <p>
                      <span className="redText">Fjóla: </span>
                      {t("partThreeLetsRecap:content.line12")}{" "}
                      <span style={{ textDecoration: "underline" }}>
                        {t("partThreeLetsRecap:content.line13")}
                      </span>
                    </p>
                    <p>
                      <span className="redText">Susan: </span>
                      {t("partThreeLetsRecap:content.line14")}
                    </p>
                    <p>
                      <span className="redText">Fjóla: </span>
                      {t("partThreeLetsRecap:content.line15")}{" "}
                      <span style={{ textDecoration: "underline" }}>
                        {t("partThreeLetsRecap:content.line16")}
                      </span>
                    </p>
                    <p>
                      <span className="redText">Susan: </span>I
                      {t("partThreeLetsRecap:content.line17")}
                    </p>
                    <h5 className="redText">
                      {t("partThreeLetsRecap:content.line18")}
                    </h5>
                    <p>{t("partThreeLetsRecap:content.line19")}</p>
                    <p>{t("partThreeLetsRecap:content.line20")}</p>
                  </div>
                  {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                </div>

                <div className="row">
                  <div className="previous1 col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={previousPage}
                    >
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={nextPage}
                    >
                      {t("actions:continue")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default LetsRecap;
