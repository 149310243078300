import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { useTranslation } from "react-i18next";

const Part5Third = () => {
  const { t } = useTranslation(["partFiveThird", "actions"]);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate("/part_5/advantages_and_disadvantages_of_shoulds", {
      replace: true,
    });
  };

  const previousPage = () => {
    navigate(`/part_5/tyranny_of_the_shoulds`, { replace: true });
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partFiveThird:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">{t("partFiveThird:title")}</div>
                    <p>{t("partFiveThird:content.line1")}</p>
                    <p className="title mb-5 mt-5 text-center subtitle-text">
                      {t("partFiveThird:content.line2")}
                    </p>
                    <div className="row mb-5">
                      <div className="col-lg-6">
                        <table className="thinking_table left_table" border="0">
                          <tbody>
                            <tr className="questions">
                              <td>
                                {t("partFiveThird:content.line3")}
                                <br />
                              </td>
                            </tr>
                            <tr className="answers">
                              <td>
                                <p>{t("partFiveThird:content.line4")}</p>
                                <p>{t("partFiveThird:content.line5")}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-6">
                        <table className="thinking_table left_table" border="0">
                          <tbody>
                            <tr className="questions">
                              <td>{t("partFiveThird:content.line6")}</td>
                            </tr>
                            <tr className="answers">
                              <td>
                                <div>
                                  <p>{t("partFiveThird:content.line7")}</p>
                                  <p>{t("partFiveThird:content.line8")}</p>
                                  <p>{t("partFiveThird:content.line9")}</p>
                                  <p>{t("partFiveThird:content.line10")}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <p>{t("partFiveThird:content.line11")}</p>
                    <p>{t("partFiveThird:content.line12")}</p>
                    <p>{t("partFiveThird:content.line13")}</p>
                    <p>{t("partFiveThird:content.line14")}</p>
                    <p>{t("partFiveThird:content.line15")}</p>
                  </div>
                  {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                </div>
                <div className="row">
                  <div className="previous1 col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={previousPage}
                    >
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={nextPage}
                    >
                      {t("actions:continue")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Part5Third;
