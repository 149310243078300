import React from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetWhatWasLearned } from '../../../../services/part4/what-was-learned';
import axios from 'axios';
import { BASE_URL } from '../../../../env/Baseurl';
import RefreshToken from "../../../../services/refresh-token";
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';

const Part4ThirteenthPage = () => {

    const { t } = useTranslation(["partFourThirteenthPage", "actions"])

    const [whatWasLearned, errorMessage] = GetWhatWasLearned();              // Get Learned Data from api

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const nextPage = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);
        try {
            const response = await axios.post(BASE_URL + `page_partUpdate_setto_social_perfection/${user.userInfo._id}`, {}, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })

            navigate('/part_4/perfectionism', { replace: true });
        }
        catch (error) {

            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id);
                nextPage();

            }

        }

    };

    const previousPage = () => {

        navigate(`/part_4/what_was_learned`, { replace: true })

    };


    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title> {t('partFourThirteenthPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-12 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">

                                        <div className="title mb-3">
                                        {t('partFourThirteenthPage:title')}
                                        </div>
                                        <p className='redText'>
                                        {t('partFourThirteenthPage:content.line1')} <i>{whatWasLearned.length !== 0 && <>{whatWasLearned.the_task_i_was_compleated}</>}</i>. {t('partFourThirteenthPage:content.line2')}
                                        </p>
                                        <p>
                                        {t('partFourThirteenthPage:content.line3')}
                                        </p>
                                        <p>
                                        {t('partFourThirteenthPage:content.line4')}
                                        </p>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}

                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={nextPage}
                                        >
                                            {t('actions:continue')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4ThirteenthPage;