import React from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { TailSpin } from 'react-loader-spinner';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetContinueReponse } from '../../../../services/part4/get-continue-reponse';
import axios from 'axios';
import { BASE_URL } from '../../../../env/Baseurl';
import RefreshToken from "../../../../services/refresh-token";
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';

const Part4FourteenthPage = () => {

    const { t } = useTranslation(["partFourFourteenthPage", "actions"])

    const [continueReponse, errorMessage] = GetContinueReponse();

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const nextPage = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        if (continueReponse.obj.counter_no >= 3) {
            navigate('/part_5/essay', { replace: true });

        }
        else {
            try {
                const response = await axios.post(BASE_URL + `page_partUpdate_setto_social_perfection/${user.userInfo._id}`, {}, {
                    "headers": {
                        "Authorization": `Bearer ${user.token}`,
                        "content-type": "application/json",
                        "languageCode": user.userInfo.languageCode,
                    },
                })

                navigate('/part_4/perfectionism', { replace: true });
            }
            catch (error) {

                if (error.response.status === 401) {
                    RefreshToken(user.userInfo._id);
                    nextPage();

                }

            }
        }

    };

    const previousPage = () => {

        navigate(`/part_4/avoid`, { replace: true })

    };

    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partFourFourteenthPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header /> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div id="main" className="col-md-12 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div className="title mb-3">
                                {t('partFourFourteenthPage:title')}
                                </div>
                                {
                                    errorMessage ? (<div className="container mt-5">
                                        <div className='row justify-content-center'>
                                            <div className="alert alert-danger text-center" role="alert">
                                                {errorMessage}
                                            </div>
                                        </div>
                                    </div>)
                                        :
                                        continueReponse !== undefined && continueReponse.length !== 0 ? (
                                            <div>
                                                <p className='redText'>
                                                    {continueReponse.obj.statement1}
                                                </p>
                                                <p>
                                                    {continueReponse.obj.statement2}
                                                </p>

                                                <div className='row'>
                                                    <div className='previous1 col-6'>

                                                        <Button
                                                            className="user-reponse-submit btn btn-primary link-sty"
                                                            onClick={previousPage}

                                                        >
                                                            {t('actions:previous')}
                                                        </Button>
                                                    </div>
                                                    <div className='next col-6'>
                                                        <Button
                                                            className="user-reponse-submit btn btn-primary link-sty"
                                                            onClick={nextPage}
                                                        >
                                                            {t('actions:continue')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                            :
                                            <div className="loader-icon">
                                                <TailSpin color="#4f5962" height={50} width={50} />
                                            </div>
                                }
                            </div>
                        </div>
                        {/* <div>
                            <FooterOfPrint/>
                        </div> */}
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4FourteenthPage;