import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';

import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetPredictionSituation } from '../../../../services/part4/predictions-situation-task';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';



const Part4SeventhPage = () => {

     /*******   Multi-language Translation  *******/
     const {t} = useTranslation(["partFourSeventhPage", "actions"])

    const [predictionSituation, errorMessage] = GetPredictionSituation();            // Get Prediction Situation info from api

    /*******   States  *******/

    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const nextPage = () => {

        navigate('/part_4/experiment_completed', { replace: true })

    };


    const previousPage = () => {

        navigate(`/part_4/prediction_probabilities`, { replace: true })

    };
    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partFourSeventhPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-12 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">

                                        <div className="title mb-3">
                                        {t('partFourSeventhPage:title')}
                                        </div>
                                        <p className='experimentHead'>
                                        {t('partFourSeventhPage:content.line1')}
                                        </p>
                                        <p>
                                            <span className='redText'>{t('partFourSeventhPage:content.line2')} </span>{predictionSituation.length !== 0 && <>{predictionSituation.situationtask_data.situation}</>}
                                        </p>
                                        <p>
                                            <span className='redText'>{t('partFourSeventhPage:content.line3')} </span> {predictionSituation.length !== 0 && <>{predictionSituation.situationtask_data.task}</>}
                                        </p>
                                        <p className='redText'>
                                        {t('partFourSeventhPage:content.line4')}
                                        </p>
                                        <ul>
                                            {
                                                errorMessage ? (<div className="container mt-5">
                                                    <div className='row justify-content-center'>
                                                        <div className="alert alert-danger text-center" role="alert">
                                                            {errorMessage}
                                                        </div>
                                                    </div>
                                                </div>)
                                                    :
                                                    predictionSituation !== undefined && predictionSituation.length !== 0 ? (
                                                        <div>
                                                            {predictionSituation.safty_behaviour_data.map((item, index) => {
                                                                const card = (
                                                                    <li key={index}>{item.question}</li>
                                                                )
                                                                return card;
                                                            })}
                                                        </div>
                                                    )
                                                        :
                                                        <div className="loader-icon">
                                                            <TailSpin color="#4f5962" height={50} width={50} />
                                                        </div>
                                            }
                                        </ul>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}

                                        >
                                           {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={nextPage}
                                        >
                                            {t('actions:continue')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4SeventhPage;