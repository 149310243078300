import React, { useState, useEffect } from 'react';
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';

import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { BASE_URL } from '../../../../env/Baseurl';
import ErrorLogging from '../../../../services/error-logs';
import RefreshToken from '../../../../services/refresh-token';
import { GetAllAvoidance } from '../../../../services/part4/get-all-avoidance';
import { GetAllUtbas } from '../../../../services/part4/get-all-utbas';
import FooterOfPrint from '../../../global/FootorOfPrint';

import { useTranslation } from 'react-i18next';



const Part4FourthPage = () => {

    /*******   Multi-language Translation *******/
    const {t} = useTranslation(["partFourFourthPage", "actions"])

    const [avoidanceQuestionnaire, avoidanceErrorMessage] = GetAllAvoidance();                  // Get Avoidance Questionnaire from api
    const [utbasQuestionnaire, utbasErrorMessage] = GetAllUtbas();                              // Get UTBAS Questionnnaire from api


    /*******   States *******/

    const [utbasState, setutbasState] = useState({});
    const [avoidanceState, setAvoidanceState] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [showMore, setShowMore] = useState({
        "avoidance": false,
        "utbas": false
    });

    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);

    const numberOfAvoidance = showMore.avoidance ? avoidanceQuestionnaire.avoidance_data.length : 10;
    const numberOfUtbas = showMore.utbas ? utbasQuestionnaire.questionnairs.length : 10;

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const postSubmission = async () => {

        const userInfo = localStorage.getItem('user');                                              // Logged in user info
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "question_id": avoidanceState.question_id
            });

            const response = await axios.post(BASE_URL + `create_overComeanxity/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            setSuccessMessage(response.data);
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                postSubmission();
            }
            else {
                setErrorMessage('Please check Part 4 Create Overcomeanxity Api');
                ErrorLogging('HTTP POST Part 4 /create_overComeanxity/userId' + error);
                handle();
            }
        }

        try {
            const params = JSON.stringify({
                "question_id": utbasState.question_id
            });
            const response = await axios.post(BASE_URL + `create_reason_overComeanxity/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            setSuccessMessage(response.data);
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                postSubmission();
            }
            else {
                setErrorMessage('Please check Part 4 Create Reason Overcomeanxity Api');
                ErrorLogging('HTTP POST  Part 4 /create_reason_overComeanxity/userId' + error);
                handle();
            }
        }



        if (disabled === false) {
            navigate('/part_4/predictions', { replace: true })
        }

    };

    const nextPage = () => {
        navigate('/part_4/predictions', { replace: true })
    };

    const previousPage = () => {
        navigate(`/part_4/why_it_really_doesnt_matter`, { replace: true })

    };

    const handleChangeUtbas = (e) => {

        const { name, value } = e.target;
        setutbasState({ "question_id": value });
    };

    const handleAvoidanceShowMore = () => {
        setShowMore({
            "avoidance": true,
        });
    };

    const handleUtbasShowMore = () => {
        setShowMore({
            "utbas": true,
        });
    };


    const handleChangeAvoidance = (e) => {
        const { name, value } = e.target;
        setAvoidanceState({ "question_id": value });

    };


    //print function
    const myprintFunction = () => {
        var realData = document.getElementById("main").innerHTML

        var secondData = document.getElementById("printAble").innerHTML
        document.getElementById('printAble').innerHTML = realData
        document.getElementById("mainContainer").style.display = "none";
        // document.getElementById("printFooter").style.display = "block";
        window.print();
        document.getElementById("mainContainer").style.display = "block";
        document.getElementById("printAble").innerHTML = secondData;
        // if (dataSubmitted === true) {
        //     document.getElementById('main').innerHTML = realData;
        // }
        if (utbasQuestionnaire !== undefined && utbasQuestionnaire.length !== 0 && utbasQuestionnaire.global_disable_status === false) {

        }
        else {
            document.getElementById('main').innerHTML = realData;
        }
    }


    /*******   Hooks  *******/


    useEffect(() => {

        if (Object.keys(avoidanceState).length === 0 || Object.keys(utbasState).length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

    }, [avoidanceState, utbasState]);

    useEffect(() => {

        if (Object.keys(utbasState).length === 1) {
            setDisabled(false);
        }

    }, [utbasState]);

    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title> {t('partFourFourthPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-12 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partFourFourthPage:title')}
                                        </div>
                                        <div>
                                        </div>
                                        <div id="edit-feared-situation-text">
                                            <div>
                                                <p className='redText'>
                                                    {avoidanceQuestionnaire.length !== 0 && <>{avoidanceQuestionnaire.statement}</>}
                                                </p>
                                            </div>
                                            <p className='newSec'>
                                                {avoidanceQuestionnaire.length !== 0 && <>{avoidanceQuestionnaire.substatement}</>}
                                            </p>

                                            <div id="edit-feared-situation">
                                                {
                                                    avoidanceErrorMessage ? (<div className="container mt-5">
                                                        <div className='row justify-content-center'>
                                                            <div className="alert alert-danger text-center" role="alert">
                                                                {avoidanceErrorMessage}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                        :
                                                        avoidanceQuestionnaire !== undefined && avoidanceQuestionnaire.length !== 0 ? (
                                                            <>
                                                                <div className="content-wrap">
                                                                    {avoidanceQuestionnaire.avoidance_data.slice(0, numberOfAvoidance).map((item, index) => {
                                                                        const card = (
                                                                            <div className="left-content checkbx-sty" key={index}>
                                                                                <InputGroup.Checkbox
                                                                                    defaultChecked={item.responce}
                                                                                    disabled={item.disable_status}
                                                                                    className="radio-size"
                                                                                    type="radio"
                                                                                    name="avoidance"
                                                                                    value={item._id}
                                                                                    onChange={handleChangeAvoidance}
                                                                                />
                                                                                {item.question}
                                                                            </div>
                                                                        )
                                                                        return card;
                                                                    })}
                                                                </div>
                                                                {avoidanceQuestionnaire.avoidance_data.length > 1 ? (
                                                                    <Button
                                                                        className='loadmoreBtn'
                                                                        onClick={handleAvoidanceShowMore}
                                                                    >
                                                                        {
                                                                            showMore.avoidance ?
                                                                                "" :
                                                                                <>
                                                                                    {t('partFourFourthPage:buttonText.button1')}
                                                                                </>
                                                                        }
                                                                    </Button>
                                                                )
                                                                    :
                                                                    ""
                                                                }
                                                            </>
                                                        )
                                                            :
                                                            <div className="loader-icon">
                                                                <TailSpin color="#4f5962" height={50} width={50} />
                                                            </div>
                                                }
                                            </div>

                                            <div>
                                                <div id="edit-reason-for-avoidance-text">
                                                    <p className='secondSec'>
                                                        {utbasQuestionnaire.length !== 0 && <>{utbasQuestionnaire.statement}</>}
                                                    </p>
                                                </div>
                                                <div id="edit-reason-for-avoidance">
                                                    {
                                                        utbasErrorMessage ? (<div className="container mt-5">
                                                            <div className='row justify-content-center'>
                                                                <div className="alert alert-danger text-center" role="alert">
                                                                    {utbasErrorMessage}
                                                                </div>
                                                            </div>
                                                        </div>)
                                                            :
                                                            utbasQuestionnaire !== undefined && utbasQuestionnaire.length !== 0 ? (
                                                                <>
                                                                    <div className="content-wrap">
                                                                        {utbasQuestionnaire.questionnairs.slice(0, numberOfUtbas).map((item, index) => {
                                                                            const card = (
                                                                                <div className="left-content checkbx-sty" key={index}>
                                                                                    <InputGroup.Checkbox
                                                                                        defaultChecked={item.responce}
                                                                                        disabled={item.disable_status}
                                                                                        className="radio-size"
                                                                                        type="radio"
                                                                                        value={item._id}
                                                                                        name="utbas"
                                                                                        onChange={handleChangeUtbas}
                                                                                    />
                                                                                    {item.question}
                                                                                </div>
                                                                            )
                                                                            return card;
                                                                        })}
                                                                    </div>
                                                                    <Button
                                                                        className='loadmoreBtn'
                                                                        onClick={handleUtbasShowMore}
                                                                    >
                                                                        {
                                                                            showMore.utbas ?
                                                                                "" :
                                                                                <>
                                                                                    {t('partFourFourthPage:buttonText.button2')}
                                                                                </>
                                                                        }
                                                                    </Button>
                                                                </>
                                                            )
                                                                :
                                                                <div className="loader-icon">
                                                                    <TailSpin color="#4f5962" height={50} width={50} />
                                                                </div>
                                                    }
                                                </div>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}

                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        {utbasQuestionnaire !== undefined && utbasQuestionnaire.length !== 0 && utbasQuestionnaire.global_disable_status === false ? (
                                            <>
                                                <span data-tip={disabled === true ? t('partFourFourthPage:toolTipText') : ""} data-for='toolTip'>
                                                    <Button
                                                        className="user-reponse-submit btn btn-primary link-sty"
                                                        onClick={postSubmission}
                                                        disabled={disabled}
                                                    >
                                                       {t('actions:continue')}
                                                    </Button>
                                                </span>
                                                <ReactTooltip id="toolTip" />
                                            </>
                                        ) :
                                            <>
                                                <Button
                                                    className="user-reponse-submit"
                                                    onClick={nextPage}
                                                >
                                                    {t('actions:continue')}
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* error message modal */}

                    <Modal show={showError} >
                        <Modal.Body>{errorMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                                Close
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4FourthPage;