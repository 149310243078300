import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useTranslation } from "react-i18next";
// import j2Image from '../../../../public/images/part3/j_edited.png';
// import j3Image from '../../../../public/images/part3/j3_edited.png';

const AvoidanceSafetyBehaviours = () => {
  const { t } = useTranslation([
    "actions",
    "partThreeAvoidanceSafetyBehaviours",
  ]);

  // ============Local Storage ==========================

  const userInfo = localStorage.getItem('user'); 
  const user = JSON.parse(userInfo);
  const languageCode =user.userInfo.languageCode;


  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate("/part_3/another_example_of_safety_behaviours", { replace: true });
  };
  const previousPage = () => {
    navigate(`/part_3/bens_model_2`, { replace: true });
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title> {t("partThreeAvoidanceSafetyBehaviours:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">
                      {t("partThreeAvoidanceSafetyBehaviours:title")}
                    </div>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line1")}
                    </p>
                    <div className="img-how-to-sty ">
                      {/* <img src={`${DOMAIN_NAME}images/part3/j2.png`} alt="j1" /> */}
                      <img
                        className="part3_j2_image"
                        src={`${DOMAIN_NAME}images/${languageCode}/part3/j_edited.png`}
                        alt="j1"
                      />
                      {/* <img className='part3_j2_image'  src={j2Image} alt="j1" /> */}
                    </div>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line2")}
                    </p>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line3")}
                    </p>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line4")}
                    </p>
                    <p className="redText">
                      {t("partThreeAvoidanceSafetyBehaviours:content.line5")}
                    </p>
                    <ul className="safety-b-list">
                      <li>
                        {t("partThreeAvoidanceSafetyBehaviours:content.line6")}
                      </li>
                      <li>
                        {t("partThreeAvoidanceSafetyBehaviours:content.line7")}
                      </li>
                      <li>
                        {t("partThreeAvoidanceSafetyBehaviours:content.line8")}
                      </li>
                      <li>
                        {t("partThreeAvoidanceSafetyBehaviours:content.line9")}
                      </li>
                      <li>
                        {t("partThreeAvoidanceSafetyBehaviours:content.line10")}
                      </li>
                    </ul>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line11")}
                    </p>
                    <div className="img-how-to-sty ">
                      {/* <img src={`${DOMAIN_NAME}images/part3/j3.png`} alt="j3" /> */}
                      <img
                        className="part3_j3_image"
                        src={`${DOMAIN_NAME}images/${languageCode}/part3/j3_edited.png`}
                        alt="j3"
                      />
                      {/* <img className='part3_j3_image' src={j3Image} alt="j3" /> */}
                    </div>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line12")}
                    </p>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line13")}
                    </p>
                    <p className="redText">
                      {t("partThreeAvoidanceSafetyBehaviours:content.line14")}
                    </p>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line15")}
                    </p>
                    <p className="redText">
                      {t("partThreeAvoidanceSafetyBehaviours:content.line16")}
                    </p>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line17")}
                    </p>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line18")}
                    </p>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line19")}
                    </p>
                    <p className="redText">
                      {t("partThreeAvoidanceSafetyBehaviours:content.line20")}
                    </p>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line21")}
                    </p>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line22")}
                    </p>
                    <p>
                      {t("partThreeAvoidanceSafetyBehaviours:content.line23")}
                    </p>
                  </div>
                  {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                </div>
                <div className="row">
                  <div className="previous1 col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={previousPage}
                    >
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={nextPage}
                    >
                      {t("actions:continue")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default AvoidanceSafetyBehaviours;
