import React from 'react';
import { Helmet } from 'react-helmet';
import ExcerciseAnswersHandout from '../templates/ExcerciseAnswersHandout';
import { GetUserResponse } from '../../../../services/part1/get-user-response';
import { useTranslation } from 'react-i18next';

const Handout1 = () => {
    const { t } = useTranslation(["handoutss"])
    
    const [userResponse, errorMessage] = GetUserResponse(1);

    const title = "Handout - Thinking Exercises";

    const content = "<div>Please compare your answers to the sample answers given for thoughts that Alex may have had that led him to feel anxious, angry, sad, or happy.</div>";
    
    return(
        <div>
            <Helmet>
                <title>{t('handoutss:handout1.helmet')}</title>
            </Helmet>
            <ExcerciseAnswersHandout 
                title={t('handoutss:handout1.title')}
                content={t('handoutss:handout1.content')}
                responses={userResponse}
                link='part_1/thinking_exercise_2'
                type={'ThinkingResponse1'}
                sitnum={1}
                error={errorMessage}
            />
        </div>
    );

};

export default Handout1;
